import React from "react";
import Container from 'react-bootstrap/Container';
import Layout from "../../Layout";

export default function About() {
  return (
    <Layout>
      <Container>
        

      </Container>
    </Layout>
  );
}
